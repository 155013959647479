import React, { useState } from "react";
import { Input, Select } from "antd";
import countries from "../data/countries.json";

const { Option } = Select;

const PhoneInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  onCodeChange: (code: string) => void;
}> = ({ value, onChange, onCodeChange }) => {
  const [selectedCode, setSelectedCode] = useState<string>("+90");

  const handlePhoneNumberChange = (newValue: string) => {
    if (/^\d*$/.test(newValue)) {
      onChange(newValue);
    }
  };

  const handleCodeChange = (code: string) => {
    setSelectedCode(code);
    onCodeChange(code);
  };

  return (
    <div>
      <Select
        style={{ width: "30%" }}
        value={selectedCode}
        onChange={handleCodeChange}
      >
        {countries.map((country) => (
          <Option key={country.code} value={country.code}>
            {country.code}
          </Option>
        ))}
      </Select>
      <Input
        style={{ width: "70%" }}
        placeholder="Phone Number"
        value={value}
        onChange={(e) => handlePhoneNumberChange(e.target.value)}
        maxLength={10}
      />
    </div>
  );
};

export default PhoneInput;
