import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, Radio, Row, Col } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import '../css/LoginPage.css';
import { useTranslation } from 'react-i18next';
import API from '../api';

const { Title, Text } = Typography;

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onFinish = async (values: { email: string; password: string }) => {
    setLoading(true);

    try {
      const authResponse = await API.post('/user/authenticate', values, {
        headers: { 'no-auth': true },
      });

      if (authResponse.status === 200) {
        const { role, userId, token } = authResponse.data;
        console.log('role:', role);
        console.log('userId:', userId);
        console.log('token:', token);

        localStorage.setItem('authToken', token);

        if (role === 'admin') {
          message.success('Login successful!');
          console.log('Navigating to /admin');
          navigate('/admin');
        } else if (role === 'user') {
          message.success('Login successful!');
          localStorage.setItem('userId', userId);
          console.log(`Navigating to /userPage/${userId}`);
          navigate(`/userPage/${userId}`);
        }
        
      }
    } catch (error) {
      message.error('Login failed!');
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageChange = (e: any) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div className="login-container">
      <Row justify="center">
        <Col xs={22} sm={18} md={12} lg={8}>
          <div className="welcome-message">
            <Title level={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('welcome')}</Title>
          </div>
          <Radio.Group
            value={language}
            onChange={handleLanguageChange}
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
          >
            <Radio.Button value="en">
              <span role="img" aria-label="English">🇬🇧</span> English
            </Radio.Button>
            <Radio.Button value="tr">
              <span role="img" aria-label="Türkçe">🇹🇷</span> Türkçe
            </Radio.Button>
            <Radio.Button value="de">
              <span role="img" aria-label="Deutsch">🇩🇪</span> Deutsch
            </Radio.Button>
          </Radio.Group>

          <Form name="login-form" onFinish={onFinish} layout="vertical">
            <Form.Item
              label={t('email')}
              name="email"
              rules={[{ required: true, message: t('pleaseEnterEmail') }]}
            >
              <Input placeholder={t('email')} />
            </Form.Item>

            <Form.Item
              label={t('password')}
              name="password"
              rules={[{ required: true, message: t('pleaseEnterPassword') }]}
            >
              <Input.Password placeholder={t('password')} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                {t('login')}
              </Button>
            </Form.Item>
          </Form>

          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text>{t('noAccount')}</Text> <Link to="/register">{t('registerNow')}</Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
