import axios from 'axios';

const API = axios.create({
  baseURL: 'http://217.160.138.215:8084/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');

  if (!config.headers['no-auth'] && token && !config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default API;
