import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../api';
import { Table, Card, Typography, Spin, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import '../css/UserDetails.css';

interface User {
  id: number;
  name: string;
  email: string;
}

interface Log {
  id: number;
  logMessage: string;
}

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation('translation');

  useEffect(() => {
    API.get(`/user/get-users`)
      .then((response) => {
        const selectedUser = response.data.find((u: User) => u.id === Number(id));
        setUser(selectedUser);
      })
      .catch(console.error);

    API.get(`/log/list/${id}`)
      .then((response) => {
        setLogs(response.data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />;
  }

  return (
    <div className="user-details-container">
      {user && (
        <Row justify="center">
          <Col xs={24} sm={22} md={16} lg={12}>
            <Card 
              title={`${user.name} ${t('detailUser')}`} 
              bordered={false} 
              className="user-card"
            >
              <Typography.Text>{t('email')}: {user.email}</Typography.Text>
            </Card>
          </Col>
        </Row>
      )}

      <Row justify="center" style={{ marginTop: '20px' }}>
        <Col xs={24} sm={22} md={16} lg={12}>
          <Typography.Title level={4} className="logs-title">{t('userLogs')}</Typography.Title>
          <Table
            dataSource={logs}
            columns={[{ title: t('detailUser'), dataIndex: 'logMessage', key: 'logMessage' }]}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            scroll={{ x: 300 }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UserDetails;
