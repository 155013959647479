import React, { useState, useEffect } from 'react';
import { Table, Card, Typography, message, Spin, Button, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { FaFire } from 'react-icons/fa';
import API from '../api';
import { Area } from '@ant-design/plots';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';

const { Title} = Typography;
const { TabPane } = Tabs;

interface User {
  id: number;
  name: string;
  email: string;
}

interface Log {
  id: number;
  logMessage: string;
  timestamp: string;
}

interface LinkedInUser {
  id: number;
  pageUrl: string;
  timestamp: string;
  follow: boolean;
  connect: boolean;
  linkedinUserName: string;
  linkedinUserWork: string;
  linkedinUserLocation: string;
  linkedinUserConnection: number;
  linkedinUserInfo: string;
}

const App: React.FC = () => {
  const { t } = useTranslation('translation');
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [linkedinUsers, setLinkedinUsers] = useState<LinkedInUser[]>([]);
  const [logMessages, setLogMessages] = useState<Log[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [graphData, setGraphData] = useState<any[]>([]);

  const navigate = useNavigate();

  const containerStyle = {
    padding: window.innerWidth <= 768 ? '8px' : '16px',
  };

  const tableResponsiveStyle = {
    overflowX: 'auto' as const,
  };

  const countdownStyle = {
    textAlign: 'center' as const,
    padding: '16px',
    background: 'linear-gradient(135deg, #ff7e5f, #feb47b)',
    color: 'white',
    borderRadius: '8px',
    fontWeight: 'bold',
    fontSize: window.innerWidth <= 768 ? '14px' : 'initial',
  };

  useEffect(() => {
    if (!id) {
      message.error("User ID not found!");
      return;
    }

    const fetchUserData = async () => {
      setLoading(true);
      try {
        const userResponse = await API.get(`/user/get-user/${id}`);
        setUser(userResponse.data);

        const linkedinResponse = userResponse.data.linkedinUsers || [];
        setLinkedinUsers(linkedinResponse);

        const logMessagesResponse = userResponse.data.logMessages || [];
        setLogMessages(logMessagesResponse);

        const allActivities = [...linkedinResponse];
        const groupedByDate = groupByDate(allActivities);
        setGraphData(groupedByDate);

      } catch (error) {
        message.error("An error occurred while fetching user data!");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();

    const interval = setInterval(() => {
      fetchUserData();
    }, 60000);

    return () => clearInterval(interval);
  }, [id]);

  const groupByDate = (activities: any[]) => {
    const dateCountMap: { [key: string]: number } = {};

    activities.forEach((activity) => {
      const date = activity.timestamp.split('T')[0];
      dateCountMap[date] = (dateCountMap[date] || 0) + 1;
    });

    const groupedData = Object.keys(dateCountMap).map((date) => ({
      date,
      userCount: dateCountMap[date]
    }));

    return groupedData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  };

  const linkedinColumns = [
    { title: t('date'), dataIndex: 'timestamp', key: 'timestamp' },
    { title: t('connection'), dataIndex: 'connect', key: 'connect', render: (connect: boolean) => connect ? t('connected') : t('pending') },
    { title: t('followed'), dataIndex: 'follow', key: 'follow', render: (follow: boolean) => follow ? t('followed') : t('notFollowed') },
    { title: t('profile'), dataIndex: 'pageUrl', key: 'pageUrl', render: (pageUrl: string) => <a href={pageUrl} target="_blank" rel="noopener noreferrer">{t('profile')}</a> },
    { title: t('username'), dataIndex: 'linkedinUserName', key: 'linkedinUserName' },
    { title: t('jobTitle'), dataIndex: 'linkedinUserWork', key: 'linkedinUserWork' },
    { title: t('location'), dataIndex: 'linkedinUserLocation', key: 'linkedinUserLocation' },
    { title: t('connectionsCount'), dataIndex: 'linkedinUserConnection', key: 'linkedinUserConnection' },
  ];

  const userColumns = [
    { title: t('date'), dataIndex: 'timestamp', key: 'timestamp' },
    { title: t('message'), dataIndex: 'logMessage', key: 'logMessage' }
  ];

  const config = {
    data: graphData,
    xField: 'date',
    yField: 'userCount',
    smooth: true,
    line: {
      style: {
        stroke: 'darkgreen',
        strokeWidth: 2,
      },
    },
    xAxis: {
      label: {
        formatter: (v: string) => v.split('-').slice(1).join('/'),
      },
    },
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    navigate('/');
  };

  const countdownRenderer = ({ days, hours, minutes, seconds }: any) => (
    <div style={countdownStyle}>
      <Title level={3} style={{color: 'white' , font:'caption'}}>
        <FaFire style={{ marginRight: '10px' , color: 'white' }} />
        {t('comingSoon')}
      </Title>
      <div>
        <span>{days} {t('days')} </span>
        <span>{hours} {t('hours')} </span>
        <span>{minutes} {t('minutes')} </span>
        <span>{seconds} {t('seconds')}</span>
      </div>
    </div>
  );

  return (
    <div style={containerStyle}>
      <Title level={2}>{user ? `${user.name} ${t('userPage')}` : t('loading')}</Title>
      <Button
        color="danger"
        variant="solid"
        style={{ marginBottom: '20px' }}
        onClick={handleLogout}
      >
        {t('logout')}
      </Button>
      {loading ? (
        <Spin size="large" tip={t('loading')} />
      ) : (
        <Tabs defaultActiveKey="1" size="large" tabPosition="top" centered>
          <TabPane tab={t('linkedinUsers')} key="1">
            <Card title={t('linkedinUsers')}>
              <div style={tableResponsiveStyle}>
                <Table
                  columns={linkedinColumns}
                  dataSource={linkedinUsers}
                  rowKey="id"
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </Card>
          </TabPane>
          <TabPane tab={t('userLogs')} key="2">
            <Card title={t('userLogs')}>
              <Table
                columns={userColumns}
                dataSource={logMessages}
                rowKey="id"
                pagination={{ pageSize: 5 }}
              />
            </Card>
          </TabPane>
          <TabPane tab={t('activityGraph')} key="3">
            <Card title={t('activityGraph')}>
              <Area {...config} />
            </Card>
          </TabPane>
          <TabPane tab={t('messages')} key="4">
            <Card title={t('messages')}>
              <Countdown date="2025-01-01T00:00:00" renderer={countdownRenderer} />
            </Card>
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default App;
