import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        welcome: "Welcome to Klarlin!",
        email: "Email",
        password: "Password",
        login: "Login",
        loginSuccessAdmin: "Login successful as Admin!",
        loginSuccessUser: "Login successful as User!",
        error: "An error occurred during login!",
        loading: "Loading...",
        userPage: "User Page",
        linkedinUsers: "LinkedIn Users",
        userLogs: "User Logs",
        messages: "Messages",
        send: "Send",
        chat: "Chat",
        activityGraph: "Activity Graph",
        logout: "Logout",
        profile: "Profile",
        connected: "Connected",
        pending: "Pending",
        username: "Username",
        followed: "Followed",
        notFollowed: "Not Followed",
        connection: "Connection",
        message: "Message",
        date: "Date",
        jobTitle: "Job Title",
        location: "Location",
        connectionsCount: "Connections Count",
        comingSoon: "Coming SOON",
        userList: "User List",
        name: "Name",
        status: "Status",
        details: "Details",
        delete: "Delete",
        areYouSureToDelete: "Are you sure you want to delete this user?",
        yes: "YES",
        no: "NO",
        detailUser: "Details of",
        days: "Days",
        hours: "Hours",
        minutes: "Minutes",
        seconds: "Seconds",
        registerTitle: "Register",
        pleaseEnterUsername: "Please enter your username!",
        pleaseEnterSurname: "Please enter your surname!",
        pleaseEnterEmail: "Please enter a valid email address!",
        pleaseEnterPhone: "Please enter your phone number!",
        pleaseEnterPassword: "Please enter your password!",
        register: "Register",
        registrationSuccess: "Registration successful!",
        registrationFailed: "Registration failed!",
        alreadyHaveAccount: "Already have an account?",
        loginHere: "Login here",
        language : "Language",
        surname : "Surname",
        phone : "Phone",
        confirmPassword : "Confirm Password",
        company : "Company",
        pleaseSelectLanguage : "Please select a language!",
        passwordMismatch : "Passwords do not match!",
        noAccount : "Don't have an account?",
        registerHere : "Register here",
        registerNow : "Register Now"
      },
    },
    tr: {
      translation: {
        welcome: "Klarline Hoş Geldiniz!",
        email: "E-posta",
        password: "Şifre",
        login: "Giriş Yap",
        loginSuccessAdmin: "Admin olarak giriş başarılı!",
        loginSuccessUser: "Kullanıcı olarak giriş başarılı!",
        error: "Giriş yapılırken bir hata oluştu!",
        loading: "Yükleniyor...",
        userPage: "Kullanıcı Sayfası",
        linkedinUsers: "LinkedIn Kullanıcıları",
        userLogs: "Kullanıcı Logları",
        messages: "Mesajlar",
        send: "Gönder",
        chat: "Sohbet",
        activityGraph: "Aktivite Grafiği",
        logout: "Çıkış Yap",
        profile: "Profil",
        connected: "Bağlantı Kuruldu",
        pending: "Beklemede",
        username: "Kullanıcı Adı",
        followed: "Takip Edildi",
        notFollowed: "Takip Edilmedi",
        connection: "Bağlantı",
        message: "Mesaj",
        date: "Tarih",
        jobTitle: "İş Ünvanı",
        location: "Konum",
        connectionsCount: "Bağlantı Sayısı",
        comingSoon: "Yakında Geliyor",
        userList: "Kullanıcı Listesi",
        name: "İsim",
        status: "Durum",
        details: "Detaylar",
        delete: "Sil",
        areYouSureToDelete: "Bu kullanıcıyı silmek istediğinizden emin misiniz?",
        yes: "EVET",
        no: "HAYIR",
        detailUser: "Detayları",
        days: "Gün",
        hours: "Saat",
        minutes: "Dakika",
        seconds: "Saniye",
        registerTitle: "Kayıt Ol",
        pleaseEnterUsername: "Lütfen kullanıcı adınızı girin!",
        pleaseEnterSurname: "Lütfen soyadınızı girin!",
        pleaseEnterEmail: "Lütfen geçerli bir e-posta adresi girin!",
        pleaseEnterPhone: "Lütfen telefon numaranızı girin!",
        pleaseEnterPassword: "Lütfen şifrenizi girin!",
        register: "Kayıt Ol",
        registrationSuccess: "Kayıt başarılı!",
        registrationFailed: "Kayıt başarısız!",
        alreadyHaveAccount: "Zaten bir hesabınız var mı?",
        loginHere: "Buradan giriş yapın",
        language : "Dil",
        surname : "Soyad",
        phone : "Telefon",
        confirmPassword : "Şifreyi Onayla",
        company : "Şirket",
        pleaseSelectLanguage : "Lütfen bir dil seçin!",
        passwordMismatch : "Şifreler eşleşmiyor!",
        noAccount : "Hesabınız yok mu?",
        registerHere : "Buradan kayıt olun",
        registerNow : "Şimdi Kayıt Ol"
      },
    },
    de: {
      translation: {
        welcome: "Willkommen bei Klarlin!",
        email: "E-Mail",
        password: "Passwort",
        login: "Einloggen",
        loginSuccessAdmin: "Erfolgreich als Administrator eingeloggt!",
        loginSuccessUser: "Erfolgreich als Benutzer eingeloggt!",
        error: "Es ist ein Fehler beim Login aufgetreten!",
        loading: "Wird geladen...",
        userPage: "Benutzerdaten",
        linkedinUsers: "LinkedIn Benutzer",
        userLogs: "Benutzerprotokolle",
        messages: "Nachrichten",
        send: "Senden",
        chat: "Chat",
        activityGraph: "Aktivitätsdiagramm",
        logout: "Abmelden",
        profile: "Profil",
        connected: "Verbunden",
        username: "Benutzername",
        pending: "Ausstehend",
        followed: "Gefolgt",
        notFollowed: "Nicht gefolgt",
        connection: "Verbindung",
        message: "Nachricht",
        date: "Datum",
        jobTitle: "Berufsbezeichnung",
        location: "Standort",
        connectionsCount: "Verbindungsanzahl",
        comingSoon: "Kommt bald",
        userList: "Benutzer Liste",
        name: "Name",
        status: "Status",
        details: "Details",
        delete: "Löschen",
        areYouSureToDelete: "Möchten Sie diesen Benutzer wirklich löschen?",
        yes: "JA",
        no: "NEIN",
        detailUser: "Einzelheiten von",
        days: "Tage",
        hours: "Stunden",
        minutes: "Minuten",
        seconds: "Sekunden",
        registerTitle: "Registrieren",
        pleaseEnterUsername: "Bitte geben Sie Ihren Benutzernamen ein!",
        pleaseEnterSurname: "Bitte geben Sie Ihren Nachnamen ein!",
        pleaseEnterEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein!",
        pleaseEnterPhone: "Bitte geben Sie Ihre Telefonnummer ein!",
        pleaseEnterPassword: "Bitte geben Sie Ihr Passwort ein!",
        register: "Registrieren",
        registrationSuccess: "Registrierung erfolgreich!",
        registrationFailed: "Registrierung fehlgeschlagen!",
        alreadyHaveAccount: "Haben Sie bereits ein Konto?",
        loginHere: "Hier einloggen",
        language : "Sprache",
        surname : "Nachname",
        phone : "Telefon",
        confirmPassword : "Passwort bestätigen",
        company : "Unternehmen",
        pleaseSelectLanguage : "Bitte wählen Sie eine Sprache!",
        passwordMismatch : "Passwörter stimmen nicht übere",
        noAccount : "Sie haben kein Konto?",
        registerHere : "Hier registrieren",
        registerNow : "Jetzt registrieren"
      },
    },
  },
  lng: "en",
  fallbackLng: ["tr", "de"],
  interpolation: {
    escapeValue: false, 
  },
});

export default i18n;
