import React, { useEffect, useState } from 'react';
import API from '../api';
import { Link } from 'react-router-dom';
import { Table, Button, Typography, Spin, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

interface User {
  id: number;
  name: string;
  email: string;
  logMessages: Array<{ id: number; logMessage: string; timestamp: string }>;
}

const UsersList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    API.get('/user/get-users')
      .then((response) => {
        setUsers(response.data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = (userId: number) => {
    API.delete(`/user/delete-user/${userId}`)
      .then(() => setUsers(users.filter((user) => user.id !== userId)))
      .catch(console.error);
  };

  const columns = [
    { title: t('name'), dataIndex: 'name', key: 'name' },
    { title: t('email'), dataIndex: 'email', key: 'email' },
    {
      title: t('status'),
      key: 'status',
      render: (_: any, user: User) => {
        const now = new Date();
        const isActive = user.logMessages.some((log) => {
          const logTime = new Date(log.timestamp);
          return (now.getTime() - logTime.getTime()) / 1000 <= 60;
        });
        return <Typography.Text type={isActive ? 'success' : 'danger'}>{isActive ? t('active') : t('inactive')}</Typography.Text>;
      },
    },
    {
      title: t('details'),
      key: 'details',
      render: (_: any, user: User) => (
        <Link to={`/user/${user.id}`}>
          <Button type="primary">{t('details')}</Button>
        </Link>
      ),
    },
    {
      title: t('delete'),
      key: 'delete',
      render: (_: any, user: User) => (
        <Popconfirm
          title={t('areYouSureToDelete')}
          onConfirm={() => handleDelete(user.id)}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <Button type="primary" danger>{t('delete')}</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Typography.Title level={3}>{t('userList')}</Typography.Title>
      {loading ? (
        <Spin style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
        <Table dataSource={users} columns={columns} rowKey="id" pagination={{ pageSize: 5 }} />
      )}
    </div>
  );
};

export default UsersList;
