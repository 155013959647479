import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRoutes from "./Routes";
import { Breadcrumb, Layout, theme, Typography } from "antd";
import logo from './assets/klarlin.png';
import API from "api";

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const getBreadcrumbName = (pathname: string) => {
  const pathMap: Record<string, string> = {
    "/": "Home",
    "/login": "Login Page",
    "/user": "Admin Page",
    "/userPage": "Dashboard",
    "/register" : "Register"
  };

  return pathMap[pathname] || "Unknown Page";
};

const DynamicBreadcrumb: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [userName, setUserName] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const userId = pathname.split("/").pop();
        const response = await API.get(`/user/get-user/${userId}`);
        setUserName(response.data.name);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    if (pathname.startsWith("/userPage")) {
      fetchUserName();
    }
  }, [pathname]);

  if (pathname.startsWith("/userPage") && userName) {
    return (
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>User: {userName}</Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  const breadcrumbName = getBreadcrumbName(pathname);

  return (
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item>{breadcrumbName}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

const App: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const isMobile = window.innerWidth <= 768;

  return (
    <Router>
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            justifyContent: isMobile ? "space-between" : "flex-start",
            padding: isMobile ? "0 16px" : "0 48px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                height: isMobile ? "30px" : "40px",
                marginRight: isMobile ? "8px" : "16px",
                borderRadius: "10px",
              }}
            />
            {!isMobile && <h2 style={{ color: "#333", margin: 0 }}>Klarlin</h2>}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: "16px",
            }}
          >
            <Text mark style={{ fontWeight: "bold" }}>LinkedIn Auto Plot</Text>
          </div>
        </Header>
        <Content style={{ padding: "0 48px" }}>
          <DynamicBreadcrumb />
          <div
            style={{
              background: colorBgContainer,
              minHeight: 280,
              padding: 24,
              borderRadius: borderRadiusLG,
            }}
          >
            <AppRoutes />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Klarlin ©{new Date().getFullYear()} Designed By Klarmeister.
        </Footer>
      </Layout>
    </Router>
  );
};

export default App;
