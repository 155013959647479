import React from "react";
import { Routes, Route } from "react-router-dom";
import UsersList from "./components/UsersList";
import UserDetails from "./components/UserDetails";
import LoginPage from "./components/LoginPage";
import UserPage from "./components/UserPage";
import RegisterPage from "components/RegisterPage";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/admin" element={<UsersList />} />
      <Route path="/user/:id" element={<UserDetails />} />
      <Route path="/userPage/:id" element={<UserPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
    </Routes>
  );
};

export default AppRoutes;
