import React, { useState } from "react";
import { Input, Button, Row, Col, Form, Typography, Radio, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneInput from "./PhoneInput";
import API from "../api";

const { Title, Text } = Typography;

const RegisterPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [selectedCode, setSelectedCode] = useState<string>("+90");
  const navigate = useNavigate();
  const { t } = useTranslation("translation");

  const onFinish = async (values: any) => {
    setLoading(true);

    if (!language) {
      message.error(t("pleaseSelectLanguage"));
      setLoading(false);
      return;
    }

    if (values.password !== values.confirmPassword) {
      message.error(t("passwordMismatch"));
      setLoading(false);
      return;
    }

    const fullPhoneNumber = `${selectedCode}${values.phone}`;

    try {
      const response = await API.post(
        "/user/add-user",
        { ...values, phone: fullPhoneNumber, language, role: "user" },
        { headers: { "no-auth": true } }
      );

      if (response.status === 200) {
        message.success(t("registrationSuccess"));
        navigate("/");
      }
    } catch (error) {
      message.error(t("registrationFailed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-container">
      <Row justify="center">
        <Col xs={22} sm={18} md={12} lg={8}>
          <div className="welcome-message">
            <Title level={2} style={{ textAlign: "center", fontWeight: "bold" }}>
              {t("registerTitle")}
            </Title>
          </div>

          <Form name="register-form" onFinish={onFinish} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("name")}
                  name="name"
                  rules={[{ required: true, message: t("pleaseEnterUsername") }]}
                >
                  <Input placeholder={t("name")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("surname")}
                  name="surname"
                  rules={[{ required: true, message: t("pleaseEnterSurname") }]}
                >
                  <Input placeholder={t("surname")} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("email")}
                  name="email"
                  rules={[{ required: true, type: "email", message: t("pleaseEnterEmail") }]}
                >
                  <Input placeholder={t("email")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("phone")}
                  name="phone"
                  rules={[{ required: true, message: t("pleaseEnterPhone") }]}
                >
                  <PhoneInput value={phone} onChange={setPhone} onCodeChange={setSelectedCode} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label={t("language")}
              name="language"
              rules={[{ required: true, message: t("pleaseSelectLanguage") }]}
            >
              <Radio.Group
                onChange={(e) => setLanguage(e.target.value)}
                value={language}
                style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginBottom: "20px" }}
              >
                <Radio.Button value="en" style={{ fontSize: "14px", padding: "4px" }}>🇬🇧 English</Radio.Button>
                <Radio.Button value="tr" style={{ fontSize: "14px", padding: "4px" }}>🇹🇷 Türkçe</Radio.Button>
                <Radio.Button value="de" style={{ fontSize: "14px", padding: "4px" }}>🇩🇪 Deutsch</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={t("company")}
              name="company"
              rules={[{ required: true, message: t("pleaseEnterCompany") }]}
            >
              <Input placeholder={t("company")} />
            </Form.Item>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("password")}
                  name="password"
                  rules={[{ required: true, message: t("pleaseEnterPassword") }]}
                >
                  <Input.Password placeholder={t("password")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("confirmPassword")}
                  name="confirmPassword"
                  rules={[{ required: true, message: t("pleaseConfirmPassword") }]}
                >
                  <Input.Password placeholder={t("confirmPassword")} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                {t("register")}
              </Button>
            </Form.Item>
          </Form>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Text>{t("alreadyHaveAccount")}</Text>{" "}
            <Button type="link" onClick={() => navigate("/")}>
              {t("loginHere")}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterPage;
